import { useState } from 'react';
import Loading from "../components/Loading";
import "./RescheduleManager.css";

function RescheduleManager({ rescheduleManager, setRescheduleManager }) {
    const localeDateOptionsDay = {day: "2-digit", month: "2-digit", year: "2-digit"};
    const localeDateOptionsTime = {hour: "2-digit", minute: "2-digit"};
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState(false);

    const eventTypes = {
        "individual-class": {
          text: "Clase Individual",
          icon: "/event-individual.svg"
        },
        "group-class": {
          text: "Clase Grupal",
          icon: "/event-group.svg"
        },
        "coaching-session": {
          text: "Coaching",
          icon: "/event-coaching.svg"
        },
      }

    function handleDeletePrevEvent() {
        setRescheduleManager({
            prevEvent: false,
            newEvent: false,
        })
        setStatusMessage(false);
    }

    function handleDeleteNewEvent() {
        setRescheduleManager((prev) => {
            return {
                ...prev,
                newEvent: false,
            }
        })
        setStatusMessage(false);
    }

    async function handleConfirm(e) {
        e.preventDefault();
        setIsLoading(true);

        const jwtToken = sessionStorage.getItem("jwtToken");
        const request = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`
        },
        body: JSON.stringify({
            prevevent: rescheduleManager.prevEvent.id,
            newevent: rescheduleManager.newEvent.id,
            timezoneOffset: new Date().getTimezoneOffset(),
        }),
        }
        const result = await fetch(`${process.env.REACT_APP_API_URL}/rescheduleEvent`, request);
        const response = await result.json();

        setStatusMessage(response.message);
        if (result.status === 201) {
            setTimeout(() => {
                setRescheduleManager({
                    prevEvent: false,
                    newEvent: false,
                })
            }, 3000)
        }
        setIsLoading(false);
    }

    return (
        <div className="rm--container">
            <div id="reschedule-manager" className="rm--body fade-in-up">
                <div className="rm--body-title"><div className="rm--body-title--container"><div className="reschedule-icon"></div>Reagenda</div></div>
                <div className="rm--body-event">
                    <div className="rm--body-event--text">Haz seleccionado el siguiente evento para <b>reagendar</b>:</div>
                    <div className="rm--body-event--details">
                        <button className="rm--body-event--delete-btn" onClick={handleDeletePrevEvent}>x</button>
                        <div className="rm--body-event--date">{rescheduleManager.prevEvent.date.toLocaleDateString(undefined, localeDateOptionsDay)}</div>
                        <div className="rm--body-event--time">{rescheduleManager.prevEvent.date.toLocaleTimeString(undefined, localeDateOptionsTime)}</div>
                        <div className="rm--body-event--type">{eventTypes[rescheduleManager.prevEvent.type].text}</div>
                    </div>
                </div>
                {rescheduleManager.newEvent === false ? 
                    <>
                        <div className="rm--body-event--placeholder">Elige tu nuevo horario en la agenda para continuar.</div>
                        <button className="rm--body--cancel-btn" onClick={handleDeletePrevEvent}>Cancelar</button>
                    </> : 
                    <>
                    <div className="rm--body-event">
                        <div className="rm--body-event--text">Confirma tu nueva <b>fecha</b> y <b>hora</b>:</div>
                        <div className="rm--body-event--details">
                            <button className="rm--body-event--delete-btn" onClick={handleDeleteNewEvent}>x</button>
                            <div className="rm--body-event--date">{new Date(rescheduleManager.newEvent.date).toLocaleDateString(undefined, localeDateOptionsDay)}</div>
                            <div className="rm--body-event--time">{new Date(rescheduleManager.newEvent.date).toLocaleTimeString(undefined, localeDateOptionsTime)}</div>
                            <div className="rm--body-event--type">{eventTypes[rescheduleManager.newEvent.type].text}</div>
                        </div>
                    </div>
                    {isLoading ? <Loading /> : statusMessage === false ? <button className="rm--body--confirm-btn" onClick={handleConfirm}>Confirmar</button> : <div className="rm--body-event--placeholder">{statusMessage}</div>}
                    </>
                }
            </div>
        </div>
    )
}

export default RescheduleManager;