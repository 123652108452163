
function RescheduleButton({ setRescheduleManager, event }) {
    function handleRescheduleClick(id, date, type) {
        setRescheduleManager((prev) => {
            return {
                ...prev,
                newEvent: {
                    id: id,
                    date: date,
                    type: type
                },
            }
        })
        const rm = document.getElementById("reschedule-manager");
        rm.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }

    return (
        <button className="event-scheduler--reschedule-btn" onClick={() => {handleRescheduleClick(event._id, event.date, event.type)}}>Elegir</button>
    )
}

export default RescheduleButton;