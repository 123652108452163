import DivDisplayer from "../components/DivDisplayer";
import "./TyC.css"

function TyC() {
    const termsAndConditions = [
        {
            title: "Términos y Condiciones",
            html: <div>Bienvenido a Charm English. Este es el sitio web charmenglish.cl (en adelante, el 'Sitio'), así como cualquier otro producto o servicio relacionado mencionado o vinculado en estos términos legales (en conjunto, los 'Servicios'). Le recomendamos leer detenidamente estos Términos y Condiciones de Uso (en adelante, los 'Términos') antes de iniciar el uso de nuestro sitio web. Al acceder o utilizar nuestro sitio, usted acepta quedar vinculado por estos Términos y cumplir con todas las leyes y regulaciones aplicables. Si no está de acuerdo con alguno de estos términos, le pedimos que no acceda ni utilice el sitio web. Le proporcionaremos un aviso previo de cualquier cambio programado en los Servicios que está utilizando. Estas modificaciones serán efectivas al ser notificadas directamente por correo electrónico o mediante publicación en el sitio web. Al continuar utilizando los servicios después de la fecha efectiva, acepta quedar vinculado por los términos modificados.</div>
        },
        {
            title: "1. Aceptación de Los Términos",
            html: <div>Estos Términos rigen su acceso y uso de Charm English (charmenglish.cl). Los Términos se aplican a todos los visitantes, usuarios y otras personas que acceden o utilizan el sitio web.</div>
        },
        {
            title: "2. Cookies",
            html: <div>Nuestro sitio web utiliza cookies. Al utilizar nuestro sitio web y aceptar estos términos y condiciones, das tu consentimiento para nuestro uso de cookies.</div>
        },
        {
            title: "3. Derechos de Propiedad Intelectual",
            html: <div>Todo el contenido en el sitio web, incluyendo, pero no limitado a, texto, gráficos, logotipos, imágenes, clips de audio, archivos digitales descargables, software y cualquier otro elemento creativo (el 'Contenido'), es propiedad de Charm English (charmenglish.cl) o sus licenciantes. El Contenido está protegido por leyes de copyright, marcas registradas y otras leyes aplicables de propiedad intelectual.</div>
        },
        {
            title: "4. Usos Prohibidos",
            html: <div>
                No se puede utilizar el sitio web de ninguna manera que viole los derechos de propiedad intelectual de Charm English (charmenglish.cl) o de terceros. Esto incluye, pero no se limita a:
                <ul>
                    <li>Copiar, modificar, distribuir, mostrar, transmitir, publicar, vender, licenciar, crear obras derivadas, o explotar de cualquier otra manera cualquier Contenido, en su totalidad o en parte, sin el permiso expreso por escrito de Charm English o del titular del copyright correspondiente.</li>
                    <li>Eliminar, alterar u oscurecer cualquier aviso de derechos de autor, marcas registradas u otros avisos de derechos de propiedad contenidos en el Contenido.</li>
                    <li>Enmarcar o replicar cualquier parte del sitio web sin el permiso expreso por escrito de Charm English.</li>
                    <li>Utilizar cualquier medio automatizado, como robots, arañas o herramientas de minería de datos, para acceder, descargar o extraer Contenido del sitio web.</li>
                </ul>Violar los derechos de propiedad intelectual de este sitio puede resultar en la terminación de su cuenta.</div>
        },
        {
            title: "5. Uso del Sitio Web",
            html: <><div>Puede acceder y utilizar el sitio web únicamente para sus fines personales no comerciales. Usted acepta no:</div>
                <ul>
                    <li>Utilizar el sitio web con fines ilegales o no autorizados.</li>
                    <li>Interferir o dañar el sitio web o los sistemas informáticos relacionados con el sitio web. </li>
                    <li>Transmitir cualquier virus u otro código malicioso a través del sitio web.</li>
                    <li>Violar los derechos de propiedad intelectual de charmenglish.cl o de terceros. </li>
                    <li>Recopilar o almacenar datos personales de otros usuarios sin su consentimiento.</li>
                </ul></>
        },
        {
            title: "6. Registro de Usuario",
            html: <div>Para acceder a ciertas funciones del sitio web, puede ser necesario que cree una cuenta. Durante el proceso de registro, se le pedirá que proporcione cierta información personal, como su nombre, dirección de correo electrónico y posiblemente otros detalles. Acepta proporcionar información precisa, completa y actualizada sobre usted. Está prohibido registrar múltiples cuentas con la intención de abusar de las funcionalidades del sitio web, proporcionar información falsa o engañosa, utilizar la identidad de otra persona o utilizar programas automatizados, scripts, robots u otros métodos similares para registrarse en una cuenta en el sitio web.</div>
        },
        {
            title: "7. Procesamiento de Pagos y Enlaces Externos",
            html: <div>El sitio web puede ofrecerle la posibilidad de comprar bienes o servicios a través de nuestra plataforma. Actualmente, integramos con PayPal y Transbank para el procesamiento seguro de pagos. Pasarelas de Pago Externas Cuando usted elige pagar un producto o servicio utilizando una de nuestras pasarelas de pago integradas, será redirigido a un sitio web seguro operado por la pasarela de pago elegida. Estos sitios web externos tienen sus propios términos y condiciones y políticas de privacidad que rigen su uso de sus servicios. Le recomendamos revisar estos documentos cuidadosamente antes de enviar su información de pago. Seguridad de Transacciones Charm English (charmenglish.cl) no recopila ni almacena su información completa de tarjeta de crédito. Todas las transacciones financieras se procesan a través de servidores seguros de nuestras pasarelas de pago elegidas. Aunque nos esforzamos por utilizar procesadores de pago reputados, no podemos hacernos responsables de cualquier violación de seguridad o problema que pueda ocurrir en los sitios web de estos procesadores de pago de terceros. Enlaces a Terceros El sitio web puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni están controlados por Charm English (charmenglish.cl). Charm English no asume ninguna responsabilidad por el contenido, políticas de privacidad o prácticas de dichos sitios web o servicios de terceros. Su Responsabilidad Usted es responsable de todos los cargos incurridos a través de su cuenta. Acepta revisar regularmente su historial de transacciones y reportar cualquier discrepancia a Charm English.</div>
        },
        {
            title: "8. Actividades Prohibidas",
            html:  <div>No puede acceder ni utilizar los servicios con ningún propósito que no sea aquel para el cual ponemos los Servicios a disposición. Los Servicios no pueden ser utilizados en conexión con ningún esfuerzo comercial excepto aquellos que estén específicamente respaldados o aprobados por nosotros. Como usuario de los Servicios, acepta no:
                <ul>
                    <li>Recuperar sistemáticamente datos u otro contenido de los servicios para crear o compilar, directa o indirectamente, una colección, compilación, base de datos o directorio sin permiso por escrito de nosotros.</li>
                    <li>Engañarnos, defraudarnos o engañar a otros usuarios, especialmente en cualquier intento de obtener información confidencial de la cuenta, como contraseñas de usuario.</li>
                    <li>Circunvenir, desactivar o interferir de alguna manera con características de seguridad de los servicios, incluyendo características que eviten o restrinjan el uso de la copia de cualquier Contenido o apliquen limitaciones al uso de los Servicios y/o el Contenido contenido en ellos.</li>
                    <li>Desprestigiar, manchar o perjudicar, en nuestra opinión, a nosotros y/o a los Servicios. Usar cualquier información obtenida de los Servicios para acosar, abusar o dañar a otra persona.</li>
                    <li>Hacer uso indebido de nuestros servicios de soporte o presentar informes falsos de abuso o mala conducta. Usar los Servicios de manera inconsistente con cualquier ley o regulación aplicable.</li>
                    <li>Usar los Servicios de manera inconsistente con cualquier ley o regulación aplicable.</li>
                    <li>Involucrarse en enmarcamiento no autorizado o enlazar a los Servicios.</li>
                    <li>Cargar o transmitir (o intentar cargar o transmitir) virus, troyanos u otro material, incluyendo el uso excesivo de letras mayúsculas y envío masivo de mensajes (publicación continua de texto repetitivo) que interfiera con el uso ininterrumpido y disfrute de los Servicios o modifique, dañe, interrumpa, altere o interfiera con el uso, características, funciones, operación o mantenimiento de los Servicios.</li>
                    <li>Interferir, interrumpir o crear una carga indebida en los Servicios o las redes o servicios conectados a los Servicios.</li>
                    <li>Hostigar, molestar, intimidar o amenazar a cualquiera de nuestros empleados o agentes que participan en la prestación de cualquier parte de los Servicios para usted.</li>
                    <li>Intentar eludir las medidas de los Servicios diseñadas para prevenir o restringir el acceso a los Servicios, o cualquier parte de los Servicios.</li>
                    <li>Copiar o adaptar el software de los Servicios, incluyendo, pero no limitado a, Flash, PHP, HTML, JavaScript u otro código.</li>
                    <li>Excepto según lo permitido por la ley aplicable, descifrar, descompilar, desensamblar o realizar ingeniería inversa de cualquiera de los software que componen o de alguna manera conforman una parte de los Servicios.</li>
                    <li>Excepto como puede ser el resultado del uso estándar del motor de búsqueda o navegador de Internet, usar, lanzar, desarrollar o distribuir cualquier sistema automatizado, incluyendo, sin limitación, cualquier araña, robot, truco o herramienta de extracción que acceda a los Servicios, o usar o lanzar cualquier script no autorizado u otro software.</li>
                    <li>Usar un agente de compra o agente de compra para realizar compras en los Servicios.</li>
                    <li>Hacer uso no autorizado de los Servicios, incluida la recopilación de nombres de usuario y/o direcciones de correo electrónico de usuarios por medios electrónicos u otros para enviar correo electrónico no solicitado, o crear cuentas de usuario por medios automatizados o bajo falsas pretensiones.</li>
                    <li>Uso de los Servicios como parte de cualquier esfuerzo para competir con nosotros o de otra manera utilizar los Servicios y/o el Contenido con fines de generación de ingresos o empresa comercial.</li>
                    <li>Cualquier actividad fraudulenta, incluyendo intentos de manipular sistemas de pago o participar en cualquier forma de mala conducta financiera.</li>
                    <li>La grabación de clases sin el consentimiento explícito de Charm English, incluyendo audio, video o cualquier otra forma de grabación.</li>
                    <li>Comportamiento disruptivo durante las clases, como acoso, lenguaje inapropiado o cualquier acción que pueda obstaculizar el entorno de aprendizaje.</li>
                    <li>Cualquier uso no autorizado o reproducción de la propiedad intelectual de Charm English, incluyendo materiales y contenido de clases.</li>
                    <li>La reventa de materiales de clase, acceso o cualquier otro servicio con fines comerciales sin el consentimiento previo por escrito de Charm English.</li>
                    <li>Compartir enlaces de clases con personas que no se hayan registrado y pagado por la clase, a menos que esté transfiriendo la clase a ellas porque no puede asistir.</li>
                    <li>Intentos de interferir o perturbar el funcionamiento normal del sitio web, sistemas de pago o cualquier tecnología asociada.</li>
                    <li>Cualquier intento no autorizado de abusar o manipular las políticas de reembolso o reprogramación. Esto resultará en sanciones o suspensión de la cuenta.</li>
                </ul></div>,
        },
        {
            title: "9. Término de Servicio",
            html: <div>Estos Términos y Condiciones se aplican al acceso y uso de nuestro sitio web y/o servicios. Nos reservamos el derecho de tomar medidas en caso de que se incumplan, a nuestra entera discreción y sin previo aviso. Las medidas que podemos tomar incluyen denegar el acceso a los servicios (bloqueando direcciones IP específicas) o cancelar su cuenta y eliminar su perfil de usuario. Estas acciones pueden tomarse por cualquier motivo, incluyendo, entre otros, el incumplimiento de estos Términos o la violación de cualquier ley o regulación. Si su cuenta se cancela por cualquier motivo, se le prohíbe registrarse y crear una nueva cuenta bajo su nombre, un nombre falso o prestado, o el nombre de cualquier tercero. Usted también tiene la opción de cancelar su cuenta en cualquier momento. Además de la cancelación, nos reservamos el derecho de emprender acciones legales apropiadas, incluyendo la búsqueda de remedios civiles, penales e injuntivos, si es necesario.</div>,
        },
        {
            title: "10. Modificaciones e Interrupciones",
            html: <div>Nos reservamos el derecho de cambiar, modificar o eliminar el contenido de los Servicios en cualquier momento o por cualquier motivo a nuestra sola discreción sin previo aviso. Sin embargo, no tenemos la obligación de actualizar ninguna información en nuestros Servicios. También nos reservamos el derecho de modificar o discontinuar total o parcialmente los Servicios sin previo aviso en cualquier momento. No seremos responsables ante usted ni ante terceros por ninguna modificación, cambio de precio, suspensión o interrupción de los Servicios. No podemos garantizar que los Servicios estén disponibles en todo momento. Podemos experimentar problemas de hardware, software u otros problemas o necesitar realizar mantenimiento relacionado con los Servicios, lo que resultará en interrupciones, demoras o errores. Nos reservamos el derecho de cambiar, revisar, actualizar, suspender, discontinuar o modificar de cualquier otra manera los Servicios en cualquier momento o por cualquier motivo sin previo aviso. Acepta que no tenemos ninguna responsabilidad por cualquier pérdida, daño o inconveniente causado por su incapacidad para acceder o utilizar los Servicios durante cualquier tiempo de inactividad o discontinuidad de los Servicios. Nada en estos Términos Legales se interpretará como una obligación de mantener y respaldar los Servicios o suministrar correcciones, actualizaciones o versiones relacionadas.</div>,
        },
        {
            title: "11. Limitación de Responsibilidad",
            html: <div>Charm English se esfuerza por proporcionar información y servicios precisos y confiables en este sitio web (charmenglish.cl). Sin embargo, no podemos garantizar que la información o los servicios sean sin errores, ininterrumpidos o completamente seguros. 
                <br></br>
                Charm English no se hará responsable de ningún daño derivado de o relacionado con su uso o incapacidad para utilizar el sitio web o su contenido. Esto incluye, pero no se limita a, daños consecuentes, incidentales, punitivos, directos o indirectos, como pérdida de beneficios, interrupción del negocio o pérdida de datos, incluso si se nos ha informado de la posibilidad de tales daños.
                <br></br>
                Exclusión de Garantías
                EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE, CHARM ENGLISH EXCLUYE TODAS LAS GARANTÍAS, EXPRESAS O IMPLÍCITAS, INCLUYENDO, PERO SIN LIMITARSE A LAS GARANTÍAS DE COMERCIABILIDAD, ADECUACIÓN PARA UN FIN DETERMINADO Y NO INFRACCIÓN.
                <br></br>
                No garantizamos que:
                <ul>
                    <li>El sitio web satisfará sus requisitos específicos.</li>
                    <li>El sitio web será ininterrumpido, oportuno, seguro o sin errores.</li>
                    <li>Los resultados obtenidos del uso del sitio web serán precisos o confiables.</li>
                    <li>La calidad de cualquier producto, servicio, información u otros materiales obtenidos a través del sitio web satisfará sus expectativas.</li>
                </ul>
                EN NINGÚN CASO CHARM ENGLISH SERÁ RESPONSABLE DE DAÑOS DIRECTOS, INDIRECTOS, INCIDENTALES, ESPECIALES, CONSECUENTES O PUNITIVOS DERIVADOS DE O EN RELACIÓN CON SU USO DEL SITIO WEB O EL CONTENIDO O SERVICIOS PROPORCIONADOS EN EL SITIO WEB, INCLUYENDO, SIN LIMITACIÓN, PÉRDIDA DE BENEFICIOS, PÉRDIDA DE DATOS, INTERRUPCIÓN DEL NEGOCIO O LESIONES PERSONALES, INCLUSO SI SE LE HA INFORMADO DE LA POSIBILIDAD DE TALES DAÑOS.
                <br></br>
                Algunas jurisdicciones pueden no permitir la exclusión o limitación de ciertas garantías o responsabilidades. En tales casos, la responsabilidad de Charm English se limitará en la medida máxima permitida por la ley aplicable.
            </div>,
        },
        {
            title: "12. Ley y Jurisdicción Aplicables",
            html: <div>Estos Términos se regirán e interpretarán de acuerdo con las leyes de Chile.</div>
        },
        {
            title: "13. Cambios en los Términos",
            html: <div>Podemos revisar y actualizar estos Términos en cualquier momento. Los cambios serán efectivos inmediatamente después de su publicación en el sitio web.</div>,
        },
        {
            title: "14. Contáctenos",
            html: <div>
                Si tiene alguna pregunta sobre estos Términos, contáctenos en soporte.charmenglishcl@gmail.com
            </div>,
        }
    ]

    return (
        <div className="tyc--container fade-in-up">
            <div className="tyc--container-title"><div className="tyc--container-title-deco"><div className="first--color"></div><div className="second--color"></div><div className="third--color"></div></div>Términos y Condiciones</div>
            <DivDisplayer elementsArray={termsAndConditions} backgroundStyle={{backgroundColor: "rgb(214, 228, 241)", color:"rgb(80,80,80)"}} backgroundStyleEven={{backgroundColor: "aliceblue", color:"rgb(80,80,80)"}} buttonStyle={{backgroundColor: "rgb(166, 138, 206)"}} />
            <div className="tyc--container-footer"></div>
        </div>
    )
}

export default TyC;