
function LogoutButton({ handleLogOut }) {
  
    return (
      <button className="nav--button dark--title" onClick={handleLogOut}>
        <img src="/login.svg" alt="Desconectar" />
        <div>
          <b>Salir</b>
        </div>
      </button>
    )
  }
  
  export default LogoutButton;