import { useState } from 'react';
import { useNavigate } from "react-router-dom";

function BuyButton({ event, addToCart, isInCart }) {
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState("Agendar");
  const inCart = isInCart(event);
  // const [statusColor, setStatusColor] = useState("rgb(255, 255, 255)");
  function handleClickAddToCart(e) {
    const result = addToCart(event);

    setStatusMessage(result.message);
    // setStatusColor(result.color);
  }
  function handleClickRouteToMyCart(e) {
    navigate("/compras");
  }
  return (
    inCart ? <button className="event-scheduler--mycart-btn" onClick={handleClickRouteToMyCart}></button> :
    <button className="event-scheduler--buy-btn" onClick={handleClickAddToCart}>{statusMessage}</button>
  )
}

export default BuyButton;