
function ErrorSign() {
  return (
    <div className='div--error'>
        <div className='div--error-sign'>⚠</div>
         ¡Ups! hubo un error cargando estos datos, porfavor intenta en otro momento.
        </div>
  )
}

export default ErrorSign;