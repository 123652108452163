import './Loading.css';

function Loading() {
  return (
    <div className="div--loading fade-in-0">
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loading;