import React from 'react';

function TopRibbon(props) {
  function deconstructToSpanArray(name) {
    let cleanName = name.trim();
    cleanName = cleanName.split("");

    if (cleanName.length < 1) {
      return [];
    }

    return cleanName;

  };

  return (
    <div className="div--ribbon">
        {deconstructToSpanArray(props.title).map((char, index) => {
          return <span className={`fade-in-${index}`} key={index}>{char}</span>
        })}
    </div>
  )
}

export default TopRibbon;