import { useState } from "react";
import Loading from '../components/Loading';
import PageNotFound from '../pages/PageNotFound';
import TopRibbon from "../components/TopRibbon";
import "./Recover.css";

function Recover({ isAuthenticated }) {
    const [statusMessage, setStatusMessage] = useState("Ingresa tu email y te enviaremos un link para cambiar tu contraseña.");
    const [isLoading, setIsLoading] = useState(false);
    const [btnVisible, setbtnVisible] = useState(true);

    async function sendRegister(email) {
        setIsLoading(true)
        try {
            const request = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email.trim(),
                })
            };
            const result = await fetch(`${process.env.REACT_APP_API_URL}/recover`, request);
            const response = await result.json();

            if (result.status === 200) {
                setbtnVisible(false);
            }
            setStatusMessage(response.message);
        } catch {
            setStatusMessage("Intenta en otro momento...")
        }
        setIsLoading(false)
    }

    function submitClick(e) {
        e.preventDefault();
        const emailInput = document.getElementById("recover--email");

        if (!emailInput.checkValidity()) {
            setStatusMessage("Porfavor ingresar una dirección de correo válida.");
            return
        }

        sendRegister(emailInput.value);
    }

    function validateInput(e) {
        const validationCircle = document.getElementById(e.target.id + "-val");
        if (e.target.checkValidity()) {
            validationCircle.style.backgroundColor = "rgb(138, 182, 243)";
        } else {
            validationCircle.style.backgroundColor = "rgb(218, 200, 221)";
        }
    }

    return (
        isAuthenticated !== false ? <PageNotFound /> :
        <>
            <TopRibbon title="Recuperar" />
            <div className="recover--body fade-in-up">
                <img src="./kitty_yarn.png" alt="Recuperar" width={60} height={60} />
                <form className="recover--form">
                    <div className="form--validation-circle" id="recover--email-val"></div><input type="email" id="recover--email" pattern="[A-Za-z0-9._+\-']+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}" size="20" placeholder="Email" onChange={validateInput} required />
                </form>
                <div className="recover--status">{isLoading ? <Loading /> : statusMessage}</div>
                <div className="recover--submit">{btnVisible && <button className="recover--submit-btn" onClick={submitClick}>Recuperar</button>}</div>
            </div>
        </> 
    
    )
};

export default Recover;