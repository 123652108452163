import {useEffect } from 'react';

function CalendarGrid({ eventDates, chosenDay, setChosenDay, setIsLoading, gridDays, setGridDays, updateCalendar }) {
  const weekdays = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado", "domingo"];

  useEffect(() => {
    setGridDays(updateCalendar(chosenDay));
  }, [chosenDay, updateCalendar, setGridDays])

  function handleClick(e) {
    setIsLoading(true);
    const x = e.target.dataset.x;
    const y = e.target.dataset.y;
    const newChosenDay = gridDays[y][x];

    if (newChosenDay.getMonth() !== chosenDay.getMonth()) {
      setGridDays(updateCalendar(newChosenDay));
    };

    setChosenDay(newChosenDay);
    setTimeout(() => {setIsLoading(false)}, 1)
    // const eventScheduler = document.getElementById("event-scheduler");
    // eventScheduler.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }

  return (
    <div className="calendar--grid">
      <div className="calendar--weekdays">
        {weekdays.map((weekday, index) => {
          return <div className={`calendar--day-title ${index > 4 ? 'weekend' : ''}`} key={index}>{weekday.toUpperCase().slice(0,3)}</div>
        })}
      </div>
      {gridDays.map((week, i) => {
        return(
          <div className="calendar--weekdays" key={i}>
            {week.map((date, j) => {
              const offMonth = date.getMonth() !== chosenDay.getMonth() ? true : false;
              const isToday = date.getDate() === chosenDay.getDate() ? true : false;
              const isAvailable = eventDates.has(`${date.getFullYear()}${date.getMonth()}${date.getDate()}`);
              return <div className={`calendar--day${offMonth ? ' off-month' : isToday ? ' is-today' : ''}`} key={j} data-x={`${j}`} data-y={`${i}`} onClick={handleClick}>
                      <div className={`calendar--day--number${isAvailable ? offMonth ? " off-month--circle" : " is-available" : ""}${isToday ? isAvailable ? " is-today--circle" : "" : ""}`} data-x={`${j}`} data-y={`${i}`} onClick={handleClick}>{date.getDate()}</div>
                    </div>
            })}
          </div>
        )
      })} 
    </div>
  )
}

export default CalendarGrid;