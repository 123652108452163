import { useNavigate } from "react-router-dom";
import NavButton from "./NavButton";
import CartButton from "./CartButton";
import LoginButton from "./LoginButton";

function NavBar({ isAuthenticated, switchLogin, cart }) {
    const navigate = useNavigate();
    return(
        <div className="nav-bar">
            <div className="nav--title">
                <img src="/logo.png" alt="charm-english logo" onClick={() => {navigate("/");}} />
            </div>
            <div className="nav--buttons">
                <NavButton title="Agenda" url="agenda" img="/calendar.svg" />
                <NavButton title="Ayuda" url="ayuda" img="/lifesaver.svg" />
                {isAuthenticated !== false && <CartButton cart={cart} />}
                {isAuthenticated === false ? <LoginButton switchLogin={switchLogin} /> : <NavButton title="Mi perfil" url="perfil" img="/profile.svg" />}
            </div>
        </div>
    );
}

export default NavBar;