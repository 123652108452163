import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Scheduler from './pages/Scheduler';
import AboutMe from './pages/AboutMe';
import ContactUs from './pages/ContactUs';
import FAQ from './pages/FAQ';
import TyC from './pages/TyC';
import MyCart from './pages/MyCart';
import MyProfile from './pages/MyProfile';
import Register from './pages/Register';
import Recover from './pages/Recover';
import PageNotFound from './pages/PageNotFound';
import LoginPopUp from './components/LoginPopUp';
import PopUpWindow from './components/PopUpWindow';
import './App.css';

function App() {
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [popUpWindow, setPopUpWindow] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cart, setCart] = useState([]);
  const [rescheduleManager, setRescheduleManager] = useState({ prevEvent: false, newEvent: false });

  function switchLogin() {
    if (loginPopUp) {
      setLoginPopUp(false)
    } else {
      setLoginPopUp(true)
    }
  }

  function hideLogin(e) {
    setLoginPopUp(false)
  }

  function addToCart(event) {
    if (isAuthenticated === false) {
      switchLogin();
      return {
        message: "Agendar"
      }
    }
    if (cart.length > 5) {
      return {
        message: "Carro lleno!",
        color: "rgb(240, 138, 138)"
      }
    }
    for (const item of cart) {
      if (item._id === event._id) {
        return {
          message: "Ya agendado",
          color: "#ffc107"
        }
      }

    }
    setCart((prev) => {
      return prev.concat([event])
    })
    return {
      message: "ok",
      color: "rgb(117, 214, 201)"
    }
  }

  function isInCart(event) {
    for (const item of cart) {
      if (item._id === event._id) {
        return true;
      };
    };
    return false;
  }

  function removeFromCart(event) {
    const eventId = event._id;
    if (cart.length === 0) {
      return
    }
    if (!cart.includes(event)) {
      return
    }
    setCart((prev) => {
      const newCart = prev.filter((event) => {
        return event._id !== eventId;
      })
      return newCart;
    })
  }

  async function refreshLogin() {
    try {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/refresh`, { credentials: "include" });
      const response = await result.json();
      if (result.status === 201) {
        sessionStorage.setItem("jwtToken", response.jwtToken);
        setIsAuthenticated({
          user: response.user,
          firstName: response.firstName,
          role: response.role
      });
      }
    } catch(e) {
      console.log("Error trying to refresh session");
    }
}

  useEffect(() => {
    refreshLogin();
  }, [])

  return (
    <div className="app--container">
      {loginPopUp && <LoginPopUp hideLogin={hideLogin} setLoginPopUp={setLoginPopUp} setIsAuthenticated={setIsAuthenticated} />}
      {popUpWindow && <PopUpWindow setPopUpWindow={setPopUpWindow} title={popUpWindow.title} content={popUpWindow.content} />}
      <BrowserRouter>
        <NavBar isAuthenticated={isAuthenticated} switchLogin={switchLogin} cart={cart} />
        <div className="page--container">
        <Routes>
          <Route path="/" element={<AboutMe setPopUpWindow={setPopUpWindow} />} />
          <Route path="/agenda" element={<Scheduler isAuthenticated={isAuthenticated} addToCart={addToCart} rescheduleManager={rescheduleManager} setRescheduleManager={setRescheduleManager} isInCart={isInCart} setPopUpWindow={setPopUpWindow} />} />
          <Route path="/contacto" element={<ContactUs isAuthenticated={isAuthenticated} />} />
          <Route path="/compras" element={<MyCart isAuthenticated={isAuthenticated} cart={cart} removeFromCart={removeFromCart} setCart={setCart} />} />
          <Route path="/ayuda" element={<FAQ />} />
          <Route path="/tyc" element={<TyC />} />
          <Route path="/perfil" element={<MyProfile isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setRescheduleManager={setRescheduleManager} setPopUpWindow={setPopUpWindow} />} />
          {process.env.REACT_APP_ALLOW_REGISTRATION === "1" && <Route path="/registro" element={<Register />} />}
          <Route path="/recuperar" element={<Recover isAuthenticated={isAuthenticated} />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
