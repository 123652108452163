import { useState } from "react";
import Loading from "./Loading";
import "./AdminButtons.css";

function AdminButtons({ event, refreshCalendarEvents, numParticipants }) {
    const [isLoading, setIsLoading] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [assignMode, setAssignMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [message, setMessage] = useState(false);

    async function deleteEvent(event) {
        try {
            setIsLoading(true);
            const jwtToken = sessionStorage.getItem("jwtToken");
            const request = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`
                },
                body: JSON.stringify({
                    eventId: event._id,
                })
            }
            const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteEvent`, request);
            const response = await result.json();

            setMessage({
                text: response.message
            });
            
            setIsLoading(false);

            if (result.status === 200) {
                refreshCalendarEvents();
            }


        } catch(e) {
            return
        }
    }

    async function assignEvent(event, studentEmail) {
        try {
            setIsLoading(true);
            const jwtToken = sessionStorage.getItem("jwtToken");
            const request = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`
                },
                body: JSON.stringify({
                    eventId: event._id,
                    studentEmail: studentEmail,
                })
            }
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assignEvent`, request);
            const response = await result.json();
            
            setMessage({
                    text: response.message
            });

            setIsLoading(false);

            if (result.status === 200) {
                refreshCalendarEvents();
            }
            
        } catch(e) {
            return
        }
    }

    // async function editEvent(event, new_topic) {
    //     try {
    //         setIsLoading(true);
    //         const jwtToken = sessionStorage.getItem("jwtToken");
    //         const request = {
    //             method: "POST",
    //             credentials: "include",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${jwtToken}`
    //             },
    //             body: JSON.stringify({
    //                 eventId: event._id,
    //             })
    //         }
    //         const result = await fetch(`${process.env.REACT_APP_API_URL}/editEvent`, request);
            
    //         setIsLoading(false);

    //         if (result.status === 200) {
    //             refreshCalendarEvents();
    //         }

    //     } catch(e) {
    //         return
    //     }
    // }

    async function handleAssignClick(e) {
        const childElement = e.target.closest("div.assign-mode--buttons");
        const parentElement = childElement.closest("div.assign-mode");
        const emailInput = parentElement.querySelector('input.assign-mode--email');
        
        await assignEvent(event, emailInput.value);
    }

    // async function handleEditClick(e) {
    //     const childElement = e.target.closest("div.assign-mode--buttons");
    //     const parentElement = childElement.closest("div.assign-mode");
    //     const newTopicInput = parentElement.querySelector('input.assign-mode--email');
        
    //     await editEvent(event, emailInput.value);
    // }

    return (
        deleteMode ? isLoading ? <Loading /> : 
        <div className="delete-mode">
            <button className="delete-mode--confirm" onClick={() => {deleteEvent(event)}}>Eliminar</button>
            <button className="delete-mode--cancel" onClick={() => {setDeleteMode(false)}}>Cancelar</button>
        </div> :
        assignMode ? isLoading ? <Loading /> : message ? <div className="message-mode">{message.text} <br></br> <button className="message-mode--btn" onClick={() => {setMessage(false)}}>{"<"}</button> </div> : 
        <div className="assign-mode">
            <input type="text" id="student-email" className="assign-mode--email" placeholder="email"></input>
            <div className="assign-mode--buttons">
                <button className="assign-mode--buttons-assign" onClick={handleAssignClick}>{">"}</button>
                <button className="assign-mode--buttons-cancel" onClick={() => {setAssignMode(false); setMessage(false)}}>x</button>
            </div>
        </div> :
        editMode ? isLoading ? <Loading /> : message ? <div className="message-mode">{message.text} <br></br> <button className="message-mode--btn" onClick={() => {setMessage(false)}}>{"<"}</button> </div> : 
        <div className="edit-mode">
            <div>Edit mode</div>
            <button className="delete-mode--cancel" onClick={() => {setEditMode(false)}}>Cancelar</button>
        </div> :
            <div className="admin-buttons">
                <button className="admin-buttons--edit" onClick={() => {setEditMode(true)}}></button>
                <button className="admin-buttons--assign" onClick={() => {setAssignMode(true)}}>{">"}</button>
                {numParticipants < 1 && <button className="admin-buttons--delete" onClick={() => {setDeleteMode(true)}}>x</button>}
            </div>
    )
}

export default AdminButtons;