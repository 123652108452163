import { useState } from "react";

function ExpandableButton({ element, index }) {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <button id={`btn-${index}`} className={`div-displayer--element-title slide-in-${ index < 10 ? index + 1 : 10 }`} onClick={() => {setVisible((prev) => {return !prev})}} >
                <div id={index} className="element-title--free" style={ index % 2 === 0 ? {backgroundColor: "rgb(166, 138, 206)"} : {backgroundColor: "rgb(196, 168, 236)"} }></div>
                <div id={index} className="element-title--text">{element.title}</div>
                <div id={index} className="element-title--free"> <div id={`button-${index}`} className="element-title--btn"> <div id={index} className={visible === true ? "element-title--btn-up" : "element-title--btn-down"}></div> </div> </div>
            </button>
            {visible === true ? <div className="div-displayer--element-text expand fade-in" id={`text-${index}`}>{element.html}</div> : ""}
        </>
  )
}

export default ExpandableButton