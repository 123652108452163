import { useState, useEffect } from 'react';
import TopRibbon from "../components/TopRibbon";
import Loading from '../components/Loading';
import "./ContactUs.css"

function ContactUs({ isAuthenticated }) {
  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  function updateCounter(e) {
    const counter = document.getElementById("status-bar--counter");
    counter.innerText = e.target.value.length;
  };

  function playAnimation() {
    const letter = document.getElementById("letter");
    letter.style.height = "100%";
    letter.style.transform = "translate(0,200)";
  }

  async function sendContactEmail(email, message) {
    setIsLoading(true);
    try {
      const request = {
        method: "POST",
        credentials: "include" ,
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email.toLowerCase(),
            message: message.toString(),
        })
    };
    const result = await fetch(`${process.env.REACT_APP_API_URL}/contactEmail`, request);
    const response = await result.json();
    if (result.status === 200) {
      playAnimation()

    };
    setStatusMessage(response.message);
    } catch(e) {
      setStatusMessage("Hubo un error al intentar enviar este mensaje...");
    }
    setIsLoading(false);
  };
  
  function handleSendClick(e) {
    e.preventDefault();
    const emailInput = document.getElementById("email-from");
    const messageInput = document.getElementById("email-message");
    if (!emailInput.checkValidity()) {
        setStatusMessage("Debes ingresar un email válido.")
        return
    }
    if (!messageInput.checkValidity()) {
        setStatusMessage("Tu mensaje debe contener entre 10 y 250 caracteres.");
        return
    }
    sendContactEmail(emailInput.value, messageInput.value);
  };

  useEffect(() => {
    if (isAuthenticated !== false) {
      const emailInput = document.getElementById("email-from");
      emailInput.value = isAuthenticated.user;
      emailInput.disabled = true;
    }
  }, [isAuthenticated])

  return (
    <>
    <TopRibbon title="Contacto" />
    <div className="div--content">
      <div className="div--contact fade-in-up">
        <div className="mail--wrapper">
          <div className="mail--lid one opening-lid-one"></div>
          <div className="mail--lid two opening-lid-two"></div>
          <div className="mail--envelope"></div>
            <form id="letter" className="mail--letter opening-letter">
              <div className="mail--letter-from">
                <label htmlFor="">De:</label>
                <input type="email" id="email-from" pattern="[A-Za-z0-9._+\-']+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}" size="20" placeholder="Email" required />
                <button className="mail--letter-send-btn" onClick={handleSendClick}>{">"}</button>
              </div>
              <div className="mail--letter-status-bar">
                <div className="mail--letter-status-bar--counter"><div id="status-bar--counter">0</div>/250</div>
                <div className="mail--letter-status-bar--message" id="status-bar--message">{isLoading ? <Loading /> : statusMessage}</div>
              </div>
              <div className="mail--letter-message">
                <textarea id="email-message" size="40" minLength={10} maxLength={250} placeholder="Tu mensaje" onChange={updateCounter}/>
              </div>
            </form>          
        </div>
      </div>
    </div>
    </>
  )
}

export default ContactUs;