import { useEffect, useState } from 'react';
import TopRibbon from '../components/TopRibbon';
import RescheduleManager from "../components/RescheduleManager";
import EventScheduler from '../components/EventScheduler';
import Calendar from '../components/Calendar';
// import Survey from "../components/Survey";
import './Scheduler.css';

function Scheduler({ isAuthenticated, addToCart, rescheduleManager, setRescheduleManager, isInCart, setPopUpWindow }) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const today = new Date();
  const [chosenDay, setChosenDay] = useState(today);
  const eventDates = data.events ? getDates(data.events) : new Set() ;
  const filteredEvents = data.events ? data.events.filter((event) => {
    const startTime = new Date(event.date);
    if ((startTime.getMonth() === chosenDay.getMonth()) && (startTime.getDate() === chosenDay.getDate())) {
      return event;
    }
    return false;
  }) : [];

  function fetchCalendarEvents() {
    fetch(`${process.env.REACT_APP_API_URL}/getEvents`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setData(data)
      })
      .then(res => {
        setIsLoading(false)
      })
      .catch(err => {
        setError(true)
      }).then(() => {
        setIsLoading(false)
      })
  };

  function refreshCalendarEvents() {
    setIsLoading(true);
    fetchCalendarEvents();
  };

  function getDates(datesArray) {
    const newSet = new Set();
    for (const event of datesArray) {
      const newDate = new Date(event.date);
      newSet.add(`${newDate.getFullYear()}${newDate.getMonth()}${newDate.getDate()}`);
    }
    return newSet;
  }

  useEffect(() => {
    fetchCalendarEvents();
  }, [])

  return (
    <>
    <TopRibbon title="Agenda ✨" />
    {/* {isAuthenticated && <Survey setPopUpWindow={setPopUpWindow} />} */}
    {(rescheduleManager.prevEvent !== false) && <RescheduleManager rescheduleManager={rescheduleManager} setRescheduleManager={setRescheduleManager} />}
    <div className='scheduler--container fade-in-up'>
      <Calendar 
        eventDates={eventDates}
        chosenDay={chosenDay}
        setChosenDay={setChosenDay}
        setIsLoading={setIsLoading} 
      />
      <EventScheduler 
        isAuthenticated={isAuthenticated}
        isLoading={isLoading}
        error={error}
        chosenDay={chosenDay}
        filteredEvents={filteredEvents}
        addToCart={addToCart}
        rescheduleManager={rescheduleManager}
        setRescheduleManager={setRescheduleManager}
        isInCart={isInCart}
        refreshCalendarEvents={refreshCalendarEvents}
        setPopUpWindow={setPopUpWindow}
      />
    </div>
    </>
  )
}

export default Scheduler;