import { useState } from "react";
import TopRibbon from "../components/TopRibbon";
import Loading from '../components/Loading';
import "./Register.css";

function Register() {
    const [statusMessage, setStatusMessage] = useState("Debes completar el formulario para continuar.");
    const [isLoading, setIsLoading] = useState(false);
    const [btnVisible, setBtnVisible] = useState(true);

    async function sendRegister(email, pw, pwConfirm, fName, lName) {
        setIsLoading(true)
        try {
            const request = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: pw,
                    password_confirm: pwConfirm,
                    firstName: fName,
                    lastName: lName,
                })
            };
            const result = await fetch(`${process.env.REACT_APP_API_URL}/register`, request);
            const response = await result.json();

            setStatusMessage(response.message);

            if (result.status === 201) {
                setBtnVisible(false);
            };

        } catch {
            setStatusMessage("No es posible registrarse en este momento...");
        }
        setIsLoading(false);
    }

    function submitClick(e) {
        e.preventDefault();
        const fNameInput = document.getElementById("register--name");
        const lNameInput = document.getElementById("register--last-name");
        const emailInput = document.getElementById("register--email");
        const pwInput = document.getElementById("register--pw");
        const pwConfirmInput = document.getElementById("register--pw-confirm");

        if (!fNameInput.checkValidity()) {
            setStatusMessage("El nombre debe tener entre 2 y 16 caracteres.");
            return
        }

        if (!lNameInput.checkValidity()) {
            setStatusMessage("El apellido debe tener entre 2 y 16 caracteres.");
            return
        }

        if (!emailInput.checkValidity()) {
            setStatusMessage("Porfavor ingresar una dirección de correo válida.");
            return
        }

        if (!pwInput.checkValidity()) {
            setStatusMessage("La contraseña debe tener entre 8 y 32 caracteres.");
            return
        }

        if (pwInput.value !== pwConfirmInput.value) {
            setStatusMessage("Verifica que las contraseñas coincidan.");
            return
        }

        sendRegister(emailInput.value, pwInput.value, pwConfirmInput.value, fNameInput.value, lNameInput.value);
    }

    function validateInput(e) {
        const validationCircle = document.getElementById(e.target.id + "-val");
        if (e.target.checkValidity()) {
            validationCircle.style.backgroundColor = "rgb(138, 182, 243)";
        } else {
            validationCircle.style.backgroundColor = "rgb(218, 200, 221)";
        }
    }

    return (
    <>
        <TopRibbon title="Registro ✨" />
        <div className="register--container">
            <div className="register--body fade-in-up">
                <form className="register--form">
                    <div className="form--validation-circle" id="register--name-val"></div><input type="text" placeholder="Nombre" id="register--name" minLength={2} maxLength={16} onChange={validateInput} required />
                    <div className="form--validation-circle" id="register--last-name-val"></div><input type="text" placeholder="Apellido" id="register--last-name" minLength={2} maxLength={16} onChange={validateInput} required />
                    <div className="form--validation-circle" id="register--email-val"></div><input type="email" id="register--email" pattern="[A-Za-z0-9._+\-']+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}" size="20" placeholder="Email" onChange={validateInput} required />
                    <div className="form--validation-circle" id="register--pw-val" ></div><input type="password" id="register--pw" size="20" minLength={8} maxLength={32} placeholder="Contraseña" onChange={validateInput} required />
                    <div className="form--validation-circle" id="register--pw-confirm-val"></div><input type="password" id="register--pw-confirm" size="20" minLength={8} maxLength={32} placeholder="Reingresar contraseña" onChange={validateInput} required />
                </form>
                <div className="register--status">{isLoading ? <Loading /> : statusMessage}</div>
                <div className="register--submit">{btnVisible && <button className="register--submit-btn" onClick={submitClick}>Enviar</button>} </div>
            </div>
        </div>
    </>
    )
};

export default Register;