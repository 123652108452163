import { useNavigate } from "react-router-dom";
import "./AboutMe.css";

function AboutMe({ setPopUpWindow }) {
  const navigate = useNavigate();
  const news = [
    {
      date: new Date(2024, 6, 10, 19, 34),
      user: "charmenglish.cl",
      title: "Reagendar",
      content: <><b>Tip:</b> Puedes reagendar una clase desde tu perfil con el botón <div className="my-profile--event-date--reschedule small-circle"></div></>,
      popup: <div className="content--popup"><div className="content--popup--news"><div className="content--date-top">{new Date(2024, 6, 10, 19, 34).toLocaleDateString(undefined, { year: "2-digit", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })}</div>
        <span>Puedes reagendar una clase con <b className="highlight--text-1">al menos 6 horas de anticipación</b> desde tu perfil presionando el botón <div className="my-profile--event-date--reschedule small-circle"></div>.</span>
        <span>Esto te llevará a la agenda en donde podrás elegir tu <b className="highlight--text-1">nuevo horario</b>.</span>
      </div></div>,
    },
    {
      date: new Date(2024, 5, 27, 15, 33),
      user: "charmenglish.cl",
      title: "¡Bienvenida, Emily!",
      content: <>¡Bienvenida, <b>Emily</b>✨!</> ,
      popup: <div className="content--popup"><div className="content--popup--news"><div className="content--date-top">{new Date(2024, 5, 27, 15, 33).toLocaleDateString(undefined, { year: "2-digit", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })}</div>
        <img className="profile-pic-2 round-border" src={`/profiles/ppec1.png`} alt="Emily" />
        <span>¡Estamos emocionados de presentar a nuestra nueva instructora: <b className="highlight--text-1">Emily</b>✨!</span>
        <span><b className="highlight--text-1">Emily</b> tiene una Maestría en Enseñanza del Inglés como Segunda Lengua y tiene una amplia experiencia como profesora de inglés y especialista en mediación educativa. Es de los Estados Unidos y ha vivido y estudiado en México, Paraguay y España, y ahora reside en Escocia. En su tiempo libre, disfruta viendo dramas de época exagerados, leyendo ficción y hablando sobre películas y arte.</span>
        <span>Cuando te inscribas en una clase, ahora verás un ícono con la foto del instructor, con una imagen de <b className="highlight--text-1">Maggie</b> o <b className="highlight--text-1">Emily</b>.</span>
      </div></div>,
    },
  ];

  function handleClickRouteToFaq(e) {
    navigate("/ayuda");
  };

  function handleClickRouteToTyc(e) {
    navigate("/tyc");
  };

  function handleClickRouteToContact(e) {
    navigate("/contacto");
  };

  function handleClickRouteToScheduler(e) {
    navigate("/agenda");
  };

  return (
    <>
    <div className="about--header">
      <span className="fade-in-0">W</span>
      <span className="fade-in-1">e</span>
      <span className="fade-in-2">l</span>
      <span className="fade-in-3">c</span>
      <span className="fade-in-4">o</span>
      <span className="fade-in-5">m</span>
      <span className="fade-in-6">e</span>
      <span className="fade-in-7">🌈</span>
    </div>
    <div className="about--container">
      <div className="div--about fade-in-up">
        <div className="div--about-bio">
          <div className="div--about-bio--title fade-in-0">
            Novedades
          </div>
          <div className="div--about-bio--text">
            {news.map((n, index) => { 
              const hasPopUp = n.popup ? true : false;
              return(
                hasPopUp === true ?
                <button className={`content--row fade-in-${3 * (index + 1)}`} key={index} onClick={() => {
                  setPopUpWindow({
                    title: n.title,
                    content: n.popup})
                  }}>
                  <div className="content--pic"></div>
                  <div className="content--title">{n.content}</div>
                  <div className="content--date">{n.date.toLocaleDateString(undefined, { year: "2-digit", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })}</div>
                </button> :
                <button className={`content--row fade-in-${3 * (index + 1)}`} key={index}>
                  <div className="content--pic"></div>
                  <div className="content--title">{n.content}</div>
                  <div className="content--date">{n.date.toLocaleDateString(undefined, { year: "2-digit", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })}</div>
                </button>
            )
            })}
          </div>
        </div>
        <div className="div--about-links fade-in-12">
          <button className="div--about-website-btn" onClick={handleClickRouteToScheduler}>
            <div className="website-btn--scheduler-icon"></div>
            <div className="website-btn--text">Agenda</div>
          </button>
          <div className="div--about-other">
            <div className="div--about-other--content">
              <button className="div--about-other--filler-btn filler-btn--highlight" onClick={handleClickRouteToFaq}><span className="jump-1">P</span><span className="jump-1">r</span>eguntas frecuentes</button>
              <button className="div--about-other--filler-btn" onClick={handleClickRouteToTyc}>Términos y condiciones</button>
              <button className="div--about-other--filler-btn" onClick={handleClickRouteToContact}>Contacto</button>
            </div>
          </div>
        </div>
        <div className="div--about-website fade-in-18">
          <div className="div--about-media">
            <div className="div--about-media--label">Síguenos en:</div>
            <a href="https://www.instagram.com/charmenglishcl" target="_blank" rel="noopener noreferrer"><img src="/ig.svg" alt="Instagram" width={80} /></a>
            <a href="https://www.tiktok.com/@charmenglishcl" target="_blank" rel="noopener noreferrer"><img src="/tiktok.png" alt="TikTok" width={80} /></a>
            <a href="https://www.linkedin.com/in/maggieleecarson" target="_blank" rel="noopener noreferrer"><img src="/linkedin.svg" alt="LinkedIn" width={80} /></a>
            <a href="https://www.buymeacoffee.com/maggiecars3" target="_blank" rel="noopener noreferrer"><img src="/buymeacoffee.svg" alt="Buy me a coffee!" width={80} /></a>
            <div className="div--about-media--label-2">@charmenglishcl</div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default AboutMe;