import ExpandableButton from './ExpandableButton';
import './DivDisplayer.css';

function DivDisplayer({ elementsArray, backgroundStyle, backgroundStyleEven, buttonStyle }) {
    return (
        <div className="div-displayer--body">
            {elementsArray.map((element, index) => {
                return (
                    <div className="div-displayer--element" key={index} >
                        <ExpandableButton element={element} index={index} />
                    </div>
                )
            })}
        </div>
    )
}

export default DivDisplayer;