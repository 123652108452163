import { Link } from 'react-router-dom';
import DivDisplayer from "../components/DivDisplayer";
import TopRibbon from "../components/TopRibbon";
import "./FAQ.css";

function FAQ() {
    const frequentlyAskedQuestions = [
        {
            title: <><div className="new--tag">Nuevo</div><div>¿Cuáles son los horarios y días de clases disponibles?</div></>,
            html: <div>Todas las horas disponibles están en la sección <Link to="/agenda" className="class--format-title slim--padding">Agenda</Link> de este sitio, las cuales <b className="class--format-price slim--padding">se ajustan a tu zona horaria</b>. El pago de las clases debes realizarlo por adelantado al agregar las clases de la agenda a tu carrito de compras. Puedes inscribirte en clases con hasta 4 semanas de anticipación.</div>
        },
        {
            title: <><div className="new--tag">Nuevo</div><div>¿Cuál es el valor de las clases?</div></>,
            html: 
            <div className="class--formats">
                <div className="class--formats-row">
                    <div className="class--format-title">Clase Grupal</div>
                    <div className="class--format-price">7.500 CLP ≈ {(7500 / Number(process.env.REACT_APP_USD_TO_CLP)).toFixed(2)} USD</div>
                    <div className="class--format-details">
                        <ul>
                            <li>Duración de 75 minutos</li>
                            <li>Pueden inscribirse hasta 4 participantes.</li>
                            <li>Ofrezco 3 niveles: <b>Pre-intermedio</b> <b className="class--format-price slim--padding">A2</b>, <b>Intermedio Bajo</b> <b className="class--format-price slim--padding">B1</b> e <b>Intermedio Alto</b> <b className="class--format-price slim--padding">B2</b> y <b className="class--format-price slim--padding">C1</b>.</li>
                            <li>Enfocadas en la conversación y expresión oral. Me presentaré con un tema con el cual trabajaremos durante la clase.</li>
                        </ul>
                    </div>
                </div>
            </div>
        },
        {
            title: <><div className="new--tag">Nuevo</div><div>¿Olvidaste tu contraseña?</div></>,
            html: <div>Haz clic en el siguiente link: <Link to="/recuperar" className="class--format-title slim--padding">Recuperar contraseña</Link> </div>
        },
        {
            title: "¿Cuál es el nivel de inglés requerido para inscribirte en las clases con Charm English?",
            html: <div>
            Puedes inscribirte en los niveles: <b>Pre-intermedio</b> <b className="class--format-price slim--padding">A2</b>, <b>Intermedio Bajo</b> <b className="class--format-price slim--padding">B1</b> e <b>Intermedio Alto</b> <b className="class--format-price slim--padding">B2</b> y <b className="class--format-price slim--padding">C1</b>. Si no conoces tu nivel, puedes enviarme un mensaje o hacer un quiz online para saber tu nivel de inglés.
            Te dejo el enlace para que puedas realizarlo. ¡Recuerda tomar captura del resultado! <a className="class--format-title slim--padding" href='https://test-english.com/level-test/' target='_blank' rel='noopener noreferrer' >https://test-english.com/level-test/</a></div>,            
        },
        {
            title: "¿Qué plataforma utilizas para las clases?",
            html: <div>Las clases se realizan a través de <b className="class--format-title slim--padding">Zoom</b>. No es necesario tener la aplicación si utilizas Zoom en tu computadora, solo debes hacer clic en el enlace para unirte. Sin embargo, si te conectas desde tu teléfono, necesitarás instalar la aplicación gratuita. En caso de conectarte desde tu teléfono, no es necesario tener una cuenta pagada, solo la aplicación.</div>
        },
        {
            title: "¿Cómo puedo pagar las clases?",
            html: <div>Puedes pagar tus clases a través de <b className="class--format-title slim--padding">Webpay Plus</b> usando tu <b className="class--format-price slim--padding">tarjeta de crédito internacional</b> o <b className="class--format-price slim--padding">tarjeta débito de Chile</b>.</div>
        },
        {
            title: "¿Qué pasa si no estoy satisfecho con las clases?",
            html: <div>Puedes enviarme un correo a soporte.charmenglishcl@gmail.com</div>
        },
        {
            title: "¿Cuáles son los métodos de enseñanza utilizados en las clases?",
            html: <div>Las clases están enfocadas en la conversación y en la comunicación. Cada clase se trata de un tema distinto de la vida real con un objetivo claro para esa clase. Por ejemplo, practicamos cómo puedes expresar una opinión, hablamos de tus apps favoritas o practicamos phrasal verbs que puedes usar en una reunión social. La idea es que tod@s tienen el tiempo para hablar, responder y escuchar en una conversación dinámica. También incluyo juegos, actividades y actuaciones para que el encuentro sea entretenido y memorable. Para cada estudiante mantengo una página de Google Classroom donde te entrego las materiales de la clase y retroalimentación personalizada. </div>
        },
        {
            title: "¿Puedo pagar una clase para una actividad en específico? Por ejemplo, una entrevista de trabajo; una entrevista para una beca.",
            html: <div>Sí que puedes. Tengo amplia experiencia ayudando a estudiantes con entrevistas de trabajo, presentaciones y revisando su CV. Puedes enviarme un correo con los detalles a contacto.charmenglishcl@gmail.com</div>
        },
        {
            title: "¿Qué pasa si necesito reprogramar o cancelar una clase?",
            html: <div>Puedes reprogramar o reagendar tu clase de forma gratuita hasta seis horas antes del inicio de la clase, permitiéndote utilizar el pago para reservar en otro día y horario. Tienes la opción de reagendar una clase hasta dos veces. 
                <br></br><br></br> En caso de cancelar después de la fecha límite (seis horas antes de la clase), no podrás realizar dicha reprogramación. En dado caso que no puedas asistir por algún motivo, también puedes transferirla a un amigo o familiar enviándole el enlace de la clase. En situaciones de emergencia, puedes contactarme para llegar a un acuerdo.
                </div>
        },
    ]

    return (
        <>
        <TopRibbon title="Ayuda" />
        <div className="faq--container fade-in-up">
            <div className="faq--container-title"><img src="/kitty_lost.png" alt="Preguntas Frequentes" width={120} height={120} />¿Necesitas ayuda? Revisa nuestra sección de preguntas frecuentes:</div>
            <DivDisplayer elementsArray={frequentlyAskedQuestions} />
            <div className="faq--container-footer"></div>
        </div>
        </>
    )
}

export default FAQ;
