import { useState, useEffect } from "react";
import "./Clock.css";

function Clock() {
   const [clockTime, setClockTime] = useState(new Date());
   const [blinker, setBlinker] = useState(false);

    async function startClock() {
        var timer = new Date();
        var isBlinking = false;
        while (true) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            isBlinking = !isBlinking;
            setBlinker(isBlinking);
            
            if (timer.getMinutes() !== new Date().getMinutes()) {
                timer = new Date();
                setClockTime(timer);
            }
        }
    }

    useEffect(() => {
        startClock();
    }, [])

    return (
        <div className="clock--container"><div>Hora local:</div><div className="clock--time">{clockTime.getHours() < 10 ? "0" + clockTime.getHours() : clockTime.getHours()}<div style={{color: blinker ? "transparent" : "rgb(110, 207, 198)"}}>:</div>{clockTime.getMinutes() < 10 ? "0" + clockTime.getMinutes() : clockTime.getMinutes()}</div></div>
    )
}

export default Clock;