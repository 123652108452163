import { Link } from 'react-router-dom';

function NavButton(props) {
    return(
        <Link to={props.url} className="nav--button">
            <img src={props.img} alt={props.title} />
            <div>
                {props.title}
            </div>
        </Link>
    );
}

export default NavButton;