import { useState, useCallback } from "react";
import CalendarGrid from "./CalendarGrid";
import Clock from "./Clock";

function Calendar({ eventDates, chosenDay, setChosenDay, setIsLoading }) {
  const currentMonth = chosenDay.toLocaleDateString('es-US', { month: 'long'});
  const currentYear = chosenDay.toLocaleDateString('en-US', { year: 'numeric'});

  const [gridDays, setGridDays] = useState([]);

  function addDays(prevDate, days) {
    var date = new Date(prevDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const updateCalendar = useCallback((date)=> {
    // set current day of the month (number)
    const currentDay = date.getDate();
    // set first day of the month
    const firstDayOfMonth = addDays(date, -currentDay + 1);
    // set minimum date to Monday of the week of the first day of the month
    const minDate = addDays(firstDayOfMonth, -(firstDayOfMonth.getDay() === 0 ? 7 : firstDayOfMonth.getDay()) + 1);
    let gridArray = [[]];
    let newDate;

    if (minDate.getDate() === 1) {
      for (let i = -7; i < 0; i++) {
        newDate = addDays(minDate, i);
        gridArray[gridArray.length-1].push(newDate);
      }
      gridArray.push([]);
    }

    for (let i=0; i<35; i++) {
      newDate = addDays(minDate, i);
      if ((i>0) && (i%7===0)) {
        gridArray.push([]);
      }
      gridArray[gridArray.length-1].push(newDate);
    }

    if (newDate.getMonth() === date.getMonth()) {
      gridArray.push([]);
      for (let i=35; i<42; i++) {
        newDate = addDays(minDate, i);
        gridArray[gridArray.length-1].push(newDate);
      }
    }

    return gridArray;
  }, []);

  function getPrevMonthDate(date) {
    let newDate = undefined;
    if (date.getMonth() === 0) {
      newDate  = new Date(date.getFullYear() -1, 11, 1);
    } else {
      newDate  = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    }
    return newDate;
  };

  function getNextMonthDate(date) {
    let newDate = undefined;
    if (date.getMonth() === 11) {
      newDate  = new Date(date.getFullYear() + 1, 0, 1);
    } else {
      newDate  = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    }
    return newDate;
  };

  function handleClickChangeMonth(e) {
    e.preventDefault();
    setIsLoading(true);

    let nextMonth;

    if (e.target.className.slice(0, 4) === "left") {
      nextMonth = getPrevMonthDate(chosenDay);
    }

    if (e.target.className.slice(0, 5) === "right") {
      nextMonth = getNextMonthDate(chosenDay);
    }
    
    setGridDays(updateCalendar(nextMonth));
    setChosenDay(nextMonth);
    setTimeout(() => {setIsLoading(false)}, 1)

  };

  return (
    <div className="calendar--container">
        <div className="calendar--sheet">
            <div className="calendar--top"><Clock /></div>
            <div className="calendar--title">
              <button className="left-arrow-btn" onClick={handleClickChangeMonth}>
                <div className="left-arrow-logo"></div>
              </button>
              <div>{currentMonth.toUpperCase()} {currentYear}</div>
              <button className="right-arrow-btn" onClick={handleClickChangeMonth}>
                <div className="right-arrow-logo"></div>
              </button>
            </div>
            <CalendarGrid
              eventDates={eventDates}
              chosenDay={chosenDay}
              setChosenDay={setChosenDay}
              setIsLoading={setIsLoading}
              gridDays={gridDays}
              setGridDays={setGridDays}
              updateCalendar={updateCalendar}
            />
            <div className="calendar--bottom"></div>
        </div>
    </div>
  )
}

export default Calendar;