import { Link } from "react-router-dom";

function CartButton({ cart }) {
  const sealColor = cart.length > 0 ? "#ffc107" : "rgba(255, 255, 255, 0.001)";
  return (
    <Link to={"/compras"} className="nav--button">
          <img src="/cart.svg" alt="Compras" />
          <div>
                Compras
          </div>
          <div className="cart-btn-light" style={{backgroundColor: sealColor}}></div>
    </Link>
  )
}

export default CartButton;