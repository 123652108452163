
function LoginButton({ switchLogin }) {
  
  return (
    <button className="nav--button" onClick={switchLogin}>
      <img src="/login.svg" alt="Mi perfil" />
      <div>
        Ingresar
      </div>
    </button>
  )
}

export default LoginButton;