
function CurrencyButton({ css, amount, currency, priceFormatter }) {

    return (
        <div className={css}>
            {currency === "USD" ? "~USD " : ""}{priceFormatter.format(amount)}
        </div>
    );
};

export default CurrencyButton;